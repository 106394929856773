import { CourseType } from '@/controllers/graphql/generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { SubDomains } from '@/constants/subdomains';

export const useShouldShowNoJobGuaranteePrepaid = (options: {
  courseType: CourseType;
}) => {
  const { courseType } = options;
  const { subDomain } = useSubDomainContext();

  return (
    subDomain === SubDomains.pl
    && courseType === CourseType.Prepaid
  );
};
