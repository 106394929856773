import { CourseType } from '@/controllers/graphql/generated';
import { ROUTES } from '@/controllers/router/router.contants';

export const getAgreementLink = (options: {
  courseSlug?: string;
  courseType?: CourseType;
}) => {
  const {
    courseSlug,
    courseType,
  } = options;

  const type = courseType === CourseType.Prepaid
    ? 'flex'
    : 'fulltime';

  if (courseSlug) {
    return ROUTES.user.agreement.index(courseSlug);
  }

  return ROUTES.user.agreement[type];
};
