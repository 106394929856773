import React, { FC } from 'react';
import { cn } from '@/lib/classNames';
import { typography } from '@/components/ui/typography';
import styles from './CourseBenefitItem.module.scss';

interface Props {
  title: string;
  titleTypography?: string;
  description: string;
  icon: React.ReactNode;
  iconTag?: string;
  iconWrapperClass?: string;
  isBorderEnabled?: boolean;
}

export const CourseBenefitItem: FC<Props> = (props) => {
  const {
    title,
    description,
    icon,
    iconTag,
    iconWrapperClass,
    isBorderEnabled = true,
    titleTypography = typography.landingH3,
  } = props;

  return (
    <div
      data-qa="benefit-item"
      className={cn(
        styles.benefitItem,
        {
          [styles.withBorder]: isBorderEnabled,
        },
      )}
    >
      <div
        className={cn(
          iconWrapperClass,
          styles.iconTag,
        )}
      >
        {icon}
        {iconTag && (
          <span className="ml-8">
            {iconTag}
          </span>
        )}
      </div>

      <h3 className={cn(titleTypography, 'color-gray-90')}>
        {title}
      </h3>

      <p className={cn(typography.landingTextMain, 'color-gray-60')}>
        {description}
      </p>
    </div>
  );
};
