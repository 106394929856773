import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconAttach: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 6.59999L7 16.05C7 18.786 9.24093 21 12 21C14.7591 21 17 18.786 17 16.05L17 7.49998L15.6364 7.49998L15.6364 16.05C15.6364 18.039 14.0091 19.65 12 19.65C9.99089 19.65 8.36364 18.039 8.36364 16.05L8.36364 6.59999C8.36364 5.35799 9.38181 4.35 10.6364 4.35C11.8909 4.35 12.9091 5.35799 12.9091 6.59999L12.9091 14.25C12.9091 14.745 12.5045 15.15 12 15.15C11.4955 15.15 11.0909 14.745 11.0909 14.25L11.0909 7.49998L9.72728 7.49998L9.72728 14.25C9.72728 15.492 10.7455 16.5 12 16.5C13.2545 16.5 14.2728 15.492 14.2728 14.25L14.2728 6.59999C14.2728 4.61097 12.6455 3 10.6364 3C8.62733 3 7 4.61097 7 6.59999Z"
      fill="currentColor"
    />
  </BaseIcon>
);
