import React, { memo } from 'react';
import { cn } from '@/lib';
import styles
  from '@/components/courseLanding/EmploymentGuarantee/Components/EmploymentBenefits/EmploymentBenefits.module.scss';
import { typography } from '@/components/ui/typography';
import { I18N_CODES } from '@/lib/constants/general';
import { CourseBenefitItem } from '@/components/courseLanding/CourseBenefitItem';
import { useTranslation } from '@/middleware/i18n';
import {
  EmploymentBenefit,
} from '@/components/courseLanding/EmploymentGuarantee/Components/EmploymentBenefits/EmploymentBenefits.typedefs';

interface Props {
  benefits: EmploymentBenefit[];
}

export const EmploymentBenefitsTemplate = memo<Props>((props) => {
  const {
    benefits,
  } = props;

  const { t } = useTranslation([I18N_CODES.course]);

  return (
    <div>
      <div className={cn(styles.benefitsSection, 'mb-32 small-mb-24')}>
        <h4 className={typography.landingH4} data-qa="employment-benefits-title">
          {t(`${I18N_CODES.course}:employment_benefits_title`)}
        </h4>
      </div>

      <div className={cn(styles.benefitsList)} data-qa="employment-benefits-section">
        {benefits.map((benefit) => (
          <CourseBenefitItem
            key={benefit.id}
            title={benefit.title}
            description={benefit.description}
            icon={benefit.icon}
            isBorderEnabled={false}
          />
        ))}
      </div>
    </div>
  );
});
