import React, { FC } from 'react';
import styles from './Sticker.module.scss';

export const LeftSticker: FC<any> = (props) => (
  <svg
    width="264"
    height="96"
    viewBox="0 0 264 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.stickerContainer}
    {...props}
  >
    <g clipPath="url(#clip0_1_10)">
      <path fillRule="evenodd" clipRule="evenodd" d="M16 0C7.1634 0 0 7.1635 0 16V64.0002C0 55.1632 0 58.5 0 64.0002L32 96.0002H248C256.836 96.0002 264 88.8362 264 80.0002V16C264 7.1635 256.836 0 248 0H16Z" fill="currentColor" />
      <path d="M28 69.5L55.0005 96.0002H32V80C32 75 29.6201 71.0901 28 69.5Z" fill="url(#paint0_linear_1_10)" fillOpacity="0.2" />
      <path
        d="M0 64.0002L32 96.0002V80.0002C32 71.1632 24.8366 64.0002 16 64.0002H0Z"
        fill="currentColor"
        style={{
          filter: 'saturate(200%) contrast(98%)',
          mixBlendMode: 'multiply',
        }}
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_1_10" x1="4.7385" y1="66.8822" x2="54.5845" y2="66.7802" gradientUnits="userSpaceOnUse">
        <stop stopColor="#B3978F" />
        <stop offset="1" stopColor="#FFF5F2" />
      </linearGradient>
      <clipPath id="clip0_1_10">
        <rect width="264" height="96.0002" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
