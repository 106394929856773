import React, { FC } from 'react';
import styles from './Sticker.module.scss';

export const RightSticker: FC<any> = (props) => (
  <svg
    width="264"
    height="96"
    viewBox="0 0 264 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.stickerContainer}
    {...props}
  >
    <g clipPath="url(#clip0_2_13)">
      <path fillRule="evenodd" clipRule="evenodd" d="M232 0.00050354H16C7.1634 0.00050354 0 7.16391 0 16.0005V80C0 88.837 7.1634 96 16 96H248C256.837 96 264 88.837 264 80V32L232 0.00050354Z" fill="currentColor" />
      <path d="M238 28.5L264 55V32H248C243.5 32 240 30 238 28.5Z" fill="url(#paint0_linear_2_13)" fillOpacity="0.2" />
      <path
        d="M264 32L232 0V16C232 24.8366 239.163 32 248 32H264Z"
        fill="currentColor"
        style={{
          filter: 'saturate(200%) contrast(98%)',
          mixBlendMode: 'multiply',
        }}
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2_13" x1="228.746" y1="18.2641" x2="228.308" y2="54.51" gradientUnits="userSpaceOnUse">
        <stop stopColor="#9F8FB3" />
        <stop offset="1" stopColor="#F8F2FF" />
      </linearGradient>
      <clipPath id="clip0_2_13">
        <rect width="264" height="96" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
