import React, {
  CSSProperties, FC, useMemo,
} from 'react';
import { cn } from '@/lib/classNames';
import { LeftSticker, RightSticker } from '@/components/courseLanding/CourseHeroSticker/Sticker';
import { typography } from '@/components/ui/typography';
import { useIntersectionObserver } from '@/hooks/useIntersectionObserver';
import styles from './CourseHeroSticker.module.scss';

export enum StickerSide {
  Left = 'left',
  Right = 'right',
}

export enum StickerColorMode {
  Orange = 'orange',
  Purple = 'purple',
  Green = 'green',
}

const stickerColorMode: Record<StickerColorMode, string> = {
  [StickerColorMode.Orange]: styles.orange,
  [StickerColorMode.Purple]: styles.purple,
  [StickerColorMode.Green]: styles.green,
};

const originSideMode: Record<StickerSide, string> = {
  [StickerSide.Left]: styles.left,
  [StickerSide.Right]: styles.right,
};

interface Props {
  title: string;
  colorMode?: StickerColorMode;
  originSide?: StickerSide;
  markSide?: StickerSide;
  width?: string;
  rotation?: {
    large?: number;
    medium?: number;
    small?: number;
  };
  rotationInverse?: boolean;
}

export const CourseHeroSticker: FC<Props> = (props) => {
  const {
    title,
    colorMode = StickerColorMode.Orange,
    originSide = StickerSide.Left,
    markSide = originSide,
    width = '264px',
    rotation = {
      large: 0,
      medium: 0,
      small: 0,
    },
    rotationInverse = false,
  } = props;

  const rotationModifier = (
    rotationInverse
      ? '-'
      : ''
  );

  const shouldShowLargeFont = useMemo(() => (
    Number(width.split('px')[0]) >= 263
  ), [width]);

  const {
    inView,
    setElementRef,
  } = useIntersectionObserver({
    initialState: false,
    toggleOnError: true,
    rootMargin: '0px 0px -100px 0px',
  });

  return (
    <div
      className={cn(
        styles.componentWrapper,
        stickerColorMode[colorMode],
        originSideMode[originSide],
        { [styles.animated]: inView },
      )}
      ref={setElementRef}
      style={{
        '--achievement-item-width': width,
        '--item-rotation-large': `${rotationModifier}${rotation.large}deg`,
        '--item-rotation-medium': `${rotationModifier}${rotation.medium}deg`,
        '--item-rotation-small': `${rotationModifier}${rotation.small}deg`,
      } as CSSProperties}
    >
      <h2 className={cn(typography.paragraph, {
        [typography.landingH6]: !shouldShowLargeFont,
        [typography.landingH5]: shouldShowLargeFont,
      })}
      >
        {title}
      </h2>

      {markSide === StickerSide.Right
        ? <RightSticker />
        : <LeftSticker />}
    </div>
  );
};
